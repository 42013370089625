bazookas.general = (function() {
  var self = {};

  self.init = function(context) {
    if (!context) {
      $('body').on('click', '.js-confirm-navigation', confirmNavigationClickHandler);
    }
  };

  /**
  * prevents default behaviour of a link and shows a confirm dialog
  * options can be overridden by data attributes on clicked button
  **/
  function confirmNavigationClickHandler(event) {
    var $link = $(this),
        options = {
          message: 'dialog.confirmRemoveMessage',
          'message-replacements': { '%element%': 'dialog.thisItem' },
          'confirm-text': 'dialog.confirm',
          'confirm-class': 'btn-danger',
          'cancel-text': 'dialog.cancel',
        };

    // extend the options object with the data from the clicked button
    // this way you can add something like: data-confirm-color="success"
    // and it will end up in the options object
    options = $.extend(options, $link.data());
    options.callback = function() {
      window.location.href = $link.attr('href');
    };

    bazookas.main.showConfirmDialog(options);

    // prevent default navigation
    return false;
  }

  return self;
})();
