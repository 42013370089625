bazookas.form = (function() {
  var self = {},
      language,
      now,
      conditionallyDetachedIndex = 0;

  self.init = function(context) {
    // get the language
    language = $('html').attr('lang');
    if (language === 'nl') {
      language = 'nl-BE';
    }

    // get the current date
    now = new Date();

    // TODO split into modules?

    // init date and time pickers
    bazookas.main
      .findInContext('.custom-datepicker .input-group.date', context)
      .initElements(initDatePicker);
    bazookas.main
      .findInContext('.custom-timepicker .input-group.date', context)
      .initElements(initTimePicker);

    bazookas.main
      .findInContext('form', context)
      .initElements(initFormSubmitSpinner);

    // file previews
    bazookas.main
      .findInContext('[data-preview-file]', context)
      .initElements(initFilePreview);

    // NOTE it is important that this one is executed AFTER other inits when using the conditionally-detached option
    bazookas.main
      .findInContext('[data-conditionally-visible]', context)
      .initElements(initConditionallyVisible);
  };

  function initDatePicker() {
    var $target = $(this),
      $input = $target.find('input'),
      format = $input.data('format') || 'dd/mm/yyyy',
      options = $.extend({
        autoclose: true,
        format: $input.data('format') || 'dd/mm/yyyy',
        language: language,
        todayHighlight: true,
        clearBtn: true
      }, $input.data('date-picker-options') || {})
    ;

    console.log(options);
    var datepicker = $target.datepicker(options);

    datepicker.datepicker('setDate', $target.find('input').val());
  }

  function initTimePicker() {
    var $target = $(this),
      $input = $target.find('input'),
      options = $.extend({
        showMeridian: false,
        disableMousewheel: true
      }, $input.data('time-picker-options') || {})
    ;

    var timepicker = $(this).timepicker(options);
    timepicker.on('changeTime.timepicker', function(e) {
      $(this).children().val(e.time.value);
    });
    timepicker.timepicker('setTime', $(this).find('input').val());
  }

  // render a form prototype
  self.renderPrototype = function($form, nestInto, replacementKey) {
    var prototype = $form.data('prototype'),
        count = $form.data('prototype-count'),
        prototypeName =  $form.data('prototype-name') || '__name__',
        regex = new RegExp(prototypeName, 'g');

    // make nested prototypes possible
    if (nestInto) {
      prototype = nestInto.replace(replacementKey || '%childprototype%', prototype);
    }

    prototype = prototype.replace(regex, count);
    var $prototype = $(prototype);

    $form.data('prototype-count', count + 1);

    bazookas.main.init($prototype);
    return $prototype;
  };

  /**
   * Conditionally visible fields
   * add data-conditionally-visible="{'selector':'condition'}" to elements
   * where 'selector' must be a valid jquery selector
   * and 'condition' must be javascript that can be evaluated, $element in the string will be filled with the element found by the 'selector'
   * see MobilefansBundle\Form\ArticleDetailsAdminType for implementation example
   * you can add as many conditions as you like, but note conditions are handled as && for now
   **/
  function initConditionallyVisible() {
    // TODO make sure this works with multiple conditions on the same target
    var $item = $(this),
        conditions = $item.data('conditionally-visible'),
        $element = $(),
        parsedConditions = [],
        eventData = {
          conditions: parsedConditions,
          $item: $item
        }
    ;

    for (var selector in conditions) {
      if (conditions.hasOwnProperty(selector)) {
        $element = $(selector);
        $element.data('condition-dirty', true);
        parsedConditions.push({
          $element: $element,
          isTrue: false,
          condition: conditions[selector]
        });

        $element.on('input change', eventData, conditionallyVisibleInputHandler);
      }
    }

    // trigger the event on the last element, the handler will loop over conditions anyway
    // but wait a little while to make sure everything is initialized on the child elements
    setTimeout(function () {
      $element.trigger('change');
    }, 100);
  }

  function conditionallyVisibleInputHandler(event) {
    var $target = $(this),
        conditions = event.data.conditions,
        isTrue = true,
        $item = event.data.$item;

    $target.data('condition-dirty', true);
    for (var i = 0; i < conditions.length; i++) {
      var condition = conditions[i],
          $element = condition.$element;
      if ($element.data('condition-dirty')) {
        // do the check
        var $value = $element.val();

        /* jshint ignore:start */
        condition.isTrue = eval(condition.condition);
        /* jshint ignore:end */

        $element.data('condition-dirty', false);
      }

      isTrue = condition.isTrue;
      if (!isTrue) {
        // if the first "false" element is found no need to check the rest
        // if they have a dirty flag they will be checked later
        break;
      }
    }

    if ($item.data('conditionally-detached')) {
      var $itemPlaceholder = $item.data('conditional-placeholder');
      if (!$itemPlaceholder) {
        $itemPlaceholder = $('<div id="conditionally-detached-placeholder-' + (conditionallyDetachedIndex++) + '">');
        $itemPlaceholder.insertBefore($item);
        $item.data('conditional-placeholder', $itemPlaceholder);
      }

      if (isTrue) {
        $item.insertAfter($itemPlaceholder);
      }
      else {
        $item.detach();
      }
    }
    else {
      if (isTrue) {
        $item.show();
      }
      else {
        $item.hide();
      }
    }

  }

  function initFilePreview() {
    var $preview = $(this),
        $input = $($preview.data('preview-file'));

    // handle file change event
    // TODO cross browser check, this will only work in "real" browsers now
    $input.on('change', function () {
      var input = this;
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (loadEvent) {
          $preview.attr('src', loadEvent.target.result);
          $preview.removeClass('hide');
        }

        reader.readAsDataURL(input.files[0]);
      }
    });
  }

  function initFormSubmitSpinner() {
    var $form = $(this);
    $form.on('submit', formSubmitHandler);
  }

  function formSubmitHandler() {
    var $form = $(this),
        $button = $form.find('[type="submit"]');
    $button.prop('disabled', true).find('.js-spinner').removeClass('hidden');
  }


  return self;
})();
