bazookas.entityPicker = (function() {
  var self = {},
      $currentPickerButton;

  self.init = function() {
    if (self.initialized) {
      return;
    }

    $('body')
      .on('click', '.js-entity-picker', entityPickerClickHandler)
      .on('click', '.js-entity-picker-item', entityPickerSelectHandler);

    $(window).
      on('message', messageHandler);

    self.initialized = true;
  };

  function entityPickerClickHandler(event) {
    event.preventDefault();
    var $button = $(this);
    $currentPickerButton = $button;
    var $frame = $('<iframe src="' + ($button.data('link') || $button.attr('href')) + '" class="entity-picker-frame">');

    // open the picker modal
    bootbox.hideAll();
    bootbox.dialog({
      title: $button.data('modal-title'),
      size: 'large',
      className: 'entity-picker-modal',
      message: $frame,
      backdrop: true,
      onEscape: true
    });

    $frame.on('load', frameLoadHandler);
  }

  function frameLoadHandler(event) {
    // auto set frame height when load handler is triggered
    var $frame = $(this);
    $frame
      .height('auto') // set to auto to make sure it's got the minimum required height
      .height($frame.contents().outerHeight() + 10) // add 10 for good measure
    ;
  }

  function messageHandler(event) {
    var data = event.originalEvent.data;
    if (data.type !== 'entityPicker') {
      return;
    }

    var entity = data.entity,
        field = $currentPickerButton.data('field'),
        $display = $(field + '_display'),
        display = entity[$currentPickerButton.data('display-field')];

    if (!display.startsWith('http') && !display.startsWith('/')) {
      display = '/' + display;
    }

    $(field).val(entity.id);

    if ($display.is('img')) {
      $display.attr('src', display);
      $display.removeClass('hide');
    }
    else {
      $display.val(display);
    }

    bootbox.hideAll();
  }

  function entityPickerSelectHandler(event) {
    var $item = $(this),
        entity = $item.data('entity');

    // let the parent know an entity has been picked
    window.parent.postMessage({
      type: 'entityPicker',
      entity: entity
    }, window.location.href);
  }

  return self;
})();
