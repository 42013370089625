bazookas.adminSelect = (function () {
  var self = {};
  self.init = function (context) {
    bazookas.main
      .findInContext('.js-select-all', context)
      .initElements(initSelectAll);
  };

  function initSelectAll() {
    //Add a hidden select all checkbox
    var $target = $(this);
    $target.prepend($('<div id="js-select-all-container" class="checkbox mar-btm"><label class="mar-btm" style="font-weight: 700;"><input class="magic-checkbox js-select-all-checkbox" type="checkbox">'+trans('admin.entities.voucher.form.selectAll')+'</label></div>'));
    $target.on('click', '.js-select-all-checkbox', selectAllHandler);
  }

  function selectAllHandler(event) {
    var $target = $(event.target);
    var $parent = $target.closest('.js-select-all');

    if ($target.is(':checked')) {
      $parent.find('input[type="checkbox"]').prop('checked', true);
    } else {
      $parent.find('input[type="checkbox"]').prop('checked', false);
    }
  }

  return self;
})();
