bazookas.libraries = (function () {
  var self = {},
    language,
    now;
  self.init = function (context) {
    bazookas.main
      .findInContext('.js-icon-picker', context)
      .initElements(setIconPickerSet);
  };

  function setIconPickerSet() {
    $(this).iconpicker({
      iconset: {
        iconClass: 'yme',
        iconClassFix: 'evycon-',
        icons: [
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'a',
          'b',
          'c',
          'd',
          'e',
          'g',
          'h',
          'i',
          'j',
          'k',
          'l',
          'm',
          'o',
          'p',
          'q',
          'r',
          's',
          't',
          'u',
          'v',
          'w',
          'x',
          'y',
          'z',
        ]
      }
    });
  }

  return self;
})();
