bazookas.listOrderable = (function() {
  var self = {},
    sortedTable;

  self.init = function (context) {
    bazookas.main
      .findInContext('.js-table-orderable tbody', context)
      .initElements(initOrderable);
  };

  function initOrderable() {
    var $table = $(this);

    sortedTable = Sortable.create($table[0], {
      handle: '.js-orderable-handle',
      draggable: 'tr',
      animation: 250,
      onSort: updateOrder
    });
  }

  function updateOrder(event) {
    //temporarily change the background of the moved item
    var $originalElement = $(event.item);
    $originalElement.addClass('bg-primary');

    setTimeout(function() {
      $originalElement.removeClass('bg-primary');
    }, 1500);

    $('.js-orderable-displayField').each(function(index) {
      var $this = $(this);

      $this.data('previous-order', $this.data('order') );
      $this.data('order', index + 1);
      $this.text((index + 1));
    });

    //temporarily disable sorting
    sortedTable.option("disabled", true);

    $('.js-table-orderable tbody').addClass('drag-disabled');

    updateEntities();
  }

  function updateEntities() {
    var data = [];

    $('.js-orderable-displayField').each(function() {
      data.push({'id': $(this).data('id'), 'order': $(this).data('order')});
    });

    $.ajax({
      type: "POST",
      url: $('.js-table-orderable').data('url'),
      data: JSON.stringify(data),
      dataType: "json",
      success: function(response) {
        //reenable sorting
        sortedTable.option("disabled", false);
        $('.js-table-orderable tbody').removeClass('drag-disabled');

        //send a notification!
        notify('success', trans('orderable.success'));
      },
      failure: function(response) {
        notify('success', trans('orderable.error'));
      }
    });
  }

  function notify(type, message) {
    $.niftyNoty({
      type: type != 'success' ? 'danger' : 'success',
      title: type != 'success' ? 'Error' : 'Success',
      message: message,
      container: 'floating',
      timer: 3000
    });
  }

  return self;
})();