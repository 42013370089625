bazookas.filters = (function () {
  var self = {};

  self.init = function (context) {
    bazookas.main
      .findInContext('.js-filter', context)
      .initElements(initConditionallyVisible);
  };

  function initConditionallyVisible() {
    var fieldInput = $('.js-filter-field');

    fieldInput.change(filterFieldChangeHandler);
    fieldInput.change();

    var $operatorInput = $('select.js-filter-operator');
    $operatorInput.val($operatorInput.data('initially-selected'));
    $operatorInput.selectpicker('refresh');

    $('.js-filter-value').change(valueChangeHandler);
    $('.js-filter-value').change();
  }

  function filterFieldChangeHandler(event) {
    var $selected = $(event.currentTarget).find(':selected'),
      type = $selected.data('type'),
      $valueInput = $('.js-filter-value'),
      $parent = $valueInput.parent()
    ;

    switch (type) {
      case 'bool':
        if (!$parent.hasClass('form-group')) {
          $(this).parent().addClass('checkbox mar-all').removeClass('form-group');
        }
        $valueInput.prop('type', 'checkbox');

        break;
      case 'text':
      default:
        if (!$parent.hasClass('form-group')) {
          $(this).parent().addClass('form-group').removeClass('checkbox mar-all');
        }
        $valueInput.prop('type', type);
        break;
    }

    //set the options for the operators input
    var $operatorInput = $('select.js-filter-operator');
    $operatorInput.find('option').remove();
    var operators = $selected.data('operators');

    for (var key in $selected.data('operators')) {
      var option = new Option(key, operators[key]);
      $operatorInput.append($(option));
    }

    $operatorInput.selectpicker('refresh');
  }

  function valueChangeHandler(event) {
    var $selected = $('.js-filter-field').find(':selected');

    if ($selected.data('type') == 'bool') {
      if ($(event.currentTarget).is(':checked')) {
        $(event.currentTarget).val(1);
      } else {
        $(event.currentTarget).val(0);
      }
    }
  }

  return self;
})();
