// small jquery plugin to call init functions
// but only call them once
// this way we don't need to check in every loop again
$.fn.initElements = function(initFunction) {
  if (typeof initFunction !== 'function') {
    return this;
  }

  for (var i = 0; i < this.length; i++) {
    var $element = $(this[i]);
    var initialized = $element.data('initialized') || [];
    if (initialized.indexOf(initFunction) > -1) {
      // continue to the next element, this one is already done
      continue;
    }

    initFunction.call($element, i, $element);
    initialized.push(initFunction);
    $element.data('initialized', initialized);
  }

  // allow chaining
  return this;
};
