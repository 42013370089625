bazookas.nestedForm = (function() {
  var self = {
    init: function(context) {
      bazookas.main
        .findInContext('.js-nested-form', context)
        .initElements(initForm);
    }
  };

  function initForm(index, $form) {
    var formID = $form.attr('id');
    if ($form.data('allow-add')) {
      var $addButton = $('<span class="btn btn-info js-add-button">' + trans('general.add') + '</span>');
      $addButton
        .data('form', $form)
        .on('click', addClickHandler)
        .insertAfter($form)
      ;
    }

    if ($form.data('sortable')) {
      // List with handle
      Sortable.create($form[0], {
        handle: '.js-sortable-item-handle',
        animation: 150
      });
      $form.on('sort', formSortHandler);

      // REVIEW is this needed? When form is rendered for the first time shouldn't sort weights be correctly set already (backend stuff)?
      updateSortWeights($form);
    }

    $form.on('click', '.js-remove-nested-item', removeClickHandler);
  }

  function formSortHandler() {
    updateSortWeights($(this));
  }

  function updateSortWeights($form) {
    if ($form.data('sortable')) {
      var $items = $form.find('.js-nested-form-item');
      for (var i = 0; i < $items.length; i++) {
        $('#' + $($items[i]).data('sortkey')).val(i);
      }
    }
  }

  function addClickHandler(event) {
    var $button = $(this),
        $form = $button.data('form'),
        $prototype = bazookas.form.renderPrototype($form, $form.data('item-container'))
    ;

    $prototype.appendTo($form);
    updateSortWeights($form);
  }

  function removeClickHandler(e) {
    e.stopPropagation();
    var $button = $(this);
    bazookas.main.showConfirmDialog({
      'message-replacements': { '%element%': 'dialog.thisItem' },
      callback: function() {
        var $form = $button.closest('.js-nested-form');
        $button.closest('.js-nested-form-item').remove();

        // NOTE technically, there is no real need to update the sort weights when deleting
        // because gaps don't prevent correct sorting
        // but it's a little cleaner if sort weight doesn't have gaps
        updateSortWeights($form);
      }
    });
  }

  return self;
})();
