bazookas.translationForm = (function() {
  var self = {
    init: function(context) {
      bazookas.main
        .findInContext('.js-translation-form', context)
        .initElements(initForm);
    }
  };

  function initForm(index, $form) {
    var requireAllLocales = $form.data('require-all-locales');
    var formID = $form.attr('id');

    intitializeOriginal($form, formID, requireAllLocales);
    initializeTabs($form, formID, requireAllLocales);

    // // add event listeners
    $form.on('click', '.js-remove-translation', removeClickHandler);
    if ($form.data('locale-count') < 2) {
      // NOTE because nifty completely fucked up the bootstrap grid system, don't addClass('col-sm-12') to the next line
      var $firstColumn = $form.find('.js-translation-form__main-column').removeClass('col-sm-6');
      $firstColumn.find('.translation-form__header').addClass('hidden');
      $firstColumn.next().addClass('hidden');
    }
  }

  // INTIALIZE THE "ORIGINAL" LANGUAGE (left panel)
  function intitializeOriginal($form, formID, requireAllLocales) {
    var $firstItem = $form.find('.js-translation-form__first');
    if ($form.data('prototype-count') === 0) {
      // create the first one
      // {% set delete_key = form.vars.id|replace({'form_': 'delete_'}) ~ "_" ~ index %}
      var $prototype = bazookas.form.renderPrototype($form);
      $('<div id="' + formID + '__translation-form__tab-0" class="js-translation-form__item">')
        .append($prototype)
        .appendTo($firstItem);

      // select the default locale
      var defaultLocale = $form.data('default-locale'),
          $defaultLocale = $prototype.find('[id$="_locale"]');
      $defaultLocale.find('option').each(function() {
        var $option = $(this);
        if ($option.val() === defaultLocale || $option.text() === defaultLocale) {
          $defaultLocale.val($option.val());
          return false;
        }
      });
    }

    var $locale = $firstItem.find('[id$="_locale"]'),
        currentLocale = $locale.val(),
        locales = {},
        localeLabels = {},
        localeCount = 0;

    $locale.find('option').each(function() {
      var locale = $(this).val();
      locales[locale] = (currentLocale === locale);
      localeLabels[locale] = $(this).text();
      localeCount++;
    });

    //only show the locale drop down if not all of the locales are required
    if (requireAllLocales) {
      $locale.hide();
      $locale.parent('.input-group').hide();
    }
    else {
      $locale.on('change', changeMainLanguageHandler);
    }

    // TODO make data object that contains all translation properties
    // this will reduce lines below and make it harder for clashing data names
    $form.data('main-locale', currentLocale);
    $form.data('locales', locales);
    $form.data('localeLabels', localeLabels);
    $form.data('localeCount', localeCount);

    //TODO can we solve this by just adding a class? CAN WEEEE??? find out on the next episode of "web team goes wild"...
    // $locale.addClass('selectpicker');
    $form.find('.js-translation-form__main-locale').append($locale);
    $locale.selectpicker();
  }

  function initializeTabs($form, formID, requireAllLocales) {
    var barID = formID + '__tab-bar',
        contentID = formID + '__tab-content',
        $tabBar = $('#' + barID),
        $tabContent = $('#' + contentID),
        locales = $form.data('locales'),
        localeLabels = $form.data('localeLabels');
    if ($tabBar.length === 0) {
      // if no tabBar is found, neither is the content
      $tabBar = $('<ul id="' + barID + '" role="tablist" class="nav nav-tabs">');
      $tabContent = $('<div id="' + contentID + '" class="js-translation-form__tab-content tab-content">');

      $('<div class="col-sm-6">').append($tabBar).append($tabContent).appendTo($form);
    }

    $tabContent.find('.js-translation-form__item').each(function(index, element) {
      var $tabContent = $(this);

      // hide the language dropdown
      var $locale = $tabContent.find('[id$="_locale"]');
      var locale = $locale.val();
      locales[locale] = true;
      $locale.hide();

      // add the tab to the tabBar
      var $tab = addTab(formID, $tabContent, locale, localeLabels[locale], $tabBar, requireAllLocales);

      if (index === 0) {
        $tab.addClass('active');
      }
      else {
        $tabContent.removeClass('active');
      }
    });
    if (!requireAllLocales) {
      createLanguageDropdown($form, formID, $tabBar);
    }
    else {
      for (var locale in locales) {
        if (locales.hasOwnProperty(locale) && !locales[locale]) {
          createLanguageTabContent(formID, $form, $tabContent, locales, locale, $tabBar, requireAllLocales);
        }
      }
    }
  }

  function createLanguageDropdown($form, formID, $tabBar) {
    var tab = '<li id="' + formID + '__add-language-picker" role="presentation" class="js-add-language-picker dropdown">';
    tab += '<a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">' + $form.data('add-translation-text') + '</a>';
    tab += '<ul class="dropdown-menu">';

    var locales = $form.data('locales'),
        localeLabels = $form.data('localeLabels');
    for (var locale in locales) {
      if (locales.hasOwnProperty(locale)) {
        var used = locales[locale];
        tab += '<li class="' + (used ? 'hidden' : '') + ' js-add-language-option-' + locale + '"><a class="js-add-language"';
        tab += 'href="#' + locale + '" data-locale="' + locale + '">' + localeLabels[locale] + '</a></li>';
      }
    }

    tab += '</ul>';
    var $tab = $(tab);
    $tab.on('click', 'a.js-add-language', addLanguageClickHandler);

    $tabBar.append($tab);
  }

  // add a tab to the tabbar and link it to the correct tab
  function addTab(formID, $tabContent, locale, localeLabel, $tabBar, requireAllLocales) {
    var tabID = $tabContent.attr('id');

    var removeButton = ' <span class="required">*</span>';
    if (!requireAllLocales) {
      removeButton = '&nbsp;&nbsp;<i class="js-remove-translation btn btn-danger btn-xs btn-circle ti-close"></span>';
    }

    var $tab = $('<li role="presentation"><a href="#' + tabID + '" aria-controls="' + tabID + '" role="tab" data-toggle="tab">' + localeLabel + removeButton + '</a></li>');

    $tabBar.append($tab);
    return $tab;
  }

  // update the languages visible in the language dropdown
  function updateLanguageDropdown($form, locales) {
    locales = locales || $form.data('locales');
    var $mainLanguagePicker = $form.find('.js-translation-form__main-locale select'),
        mainLocale = $form.data('main-locale');
    for (var locale in locales) {
      if (locales.hasOwnProperty(locale)) {
        var $item = $form.find('.js-add-language-option-' + locale);
        $mainLanguagePicker.find('option[value="' + locale + '"]').prop('disabled', locales[locale] && locale !== mainLocale);
        if (locales[locale]) {
          $item.addClass('hidden');
        }
        else {
          $item.removeClass('hidden');
        }
      }
    }
  }

  // event listeners
  function changeMainLanguageHandler(event) {
    var $select = $(this),
        currentLocale = $select.val(),
        $form = $select.closest('.js-translation-form'),
        formID = $form.attr('id'),
        previousLocale = $form.data('main-locale'),
        locales = $form.data('locales');

    locales[previousLocale] = false;
    locales[currentLocale] = true;
    $form.data('main-locale', currentLocale);
    updateLanguageDropdown($form, locales);
  }

  function addLanguageClickHandler(event) {
    var $localeLink = $(this),
        $form = $localeLink.closest('.js-translation-form'),
        $dropdown = $localeLink.closest('.js-add-language-picker'),
        selectedLocale = $localeLink.data('locale'),
        locales = $form.data('locales'),
        formID = $form.attr('id'),
        index = $form.data('prototype-count'),
        $tabContent = $('#' + formID + '__tab-content'),
        $tabBar = $('#' + formID + '__tab-bar');

    // create the tab content
    createLanguageTabContent(formID, $form, $tabContent, locales, selectedLocale, $tabBar);

    // move dropdown to the end of the list
    $tabBar.append($dropdown);
  }

  function createLanguageTabContent(formID, $form, $tabContent, locales, selectedLocale, $tabBar, requireAllLocales) {
    var index = $form.data('prototype-count'),
        localeLabels = $form.data('localeLabels'),

        // create the tab content
        $tabPane = $('<div id="' + formID + '__translation-form__tab-' + index + '" class="js-translation-form__item tab-pane">'),
        $prototype = bazookas.form.renderPrototype($form);
    $tabPane
      .append($prototype)
      .appendTo($tabContent);

    // set the locale
    var $locale = $tabPane.find('[id$="_locale"]');
    $locale.val(selectedLocale).hide();

    // create a new tab in the tabbar
    var $tab = addTab(formID, $tabPane, selectedLocale, localeLabels[selectedLocale], $tabBar, requireAllLocales);

    // make the tab active
    $tab.find('a').trigger('click');

    //set the locale as used
    locales[selectedLocale] = true;
    updateLanguageDropdown($form, locales);
  }

  function removeClickHandler(event) {
    var $button = $(this);
    bazookas.main.showConfirmDialog({
      'message-replacements': { '%element%': 'dialog.thisTranslation' },
      callback: function() {
        removeTabConfirmHandler(
          $button.closest('.js-translation-form'),
          $button.closest('[role="tab"]').attr('href')
        );
      }
    });
  }

  function removeTabConfirmHandler($form, deleteTarget) {
    var $tabPane = $(deleteTarget),
        $tab = $form.find('[href="' + deleteTarget + '"]'),
        removedLanguage = $tabPane.find('[id$="_locale"]').val(),
        wasActive = $tabPane.hasClass('active');

    // Remove item
    $tabPane.remove();
    $tab.closest('li').remove();

    if (wasActive) {
      $form.find('#' + $form.attr('id') + '__tab-bar').find('li:first a[role="tab"]').trigger('click');
    }

    var locales = $form.data('locales');
    locales[removedLanguage] = false;
    updateLanguageDropdown($form, locales);
  }

  return self;
})();
