/**
 * SIMPLE JS MODULE SYSTEM
 **/

// NOTE no need to define namespace in this project, I've made sure it is defined as the very first thing
// var bazookas = bazookas || {};

// then create the module itself
bazookas.main = (function() {
  // a module is basically a self executing function
  // returning an object with properties or methods
  var self = {};

  /**
   * Init function to do first setup or refresh parts of the page
   * @param DOM|jQuery the context in which to execute the init functions, this has no effect on the main module, but submodules should use this
   **/
  self.init = function(context) {
    // get all modules and execute the init functions
    callModuleFunctions('init', [context]);
  };

  // find elements in given context
  self.findInContext = function(selector, context) {
    if (context) {
      return $(context).find(selector);
    }
    else {
      return $(selector);
    }
  };
  // TODO self.initInContext = function (selector, context, callback) to replace inits in modules

  self.showConfirmDialog = function(options) {
    options = $.extend({
      message: 'dialog.confirmRemoveMessage',
      'message-replacements': 'dialog.thisItem',
      'confirm-text': 'dialog.confirm',
      'confirm-class': 'btn-danger',
      'cancel-text': 'dialog.cancel',
      'cancel-class': 'btn-default',
      callback: null
    }, options);
    bootbox.dialog({
      message: trans('dialog.confirmRemoveMessage', options['message-replacements']),
      title: trans('dialog.areYouSure', options['message-replacements']),
      backdrop: true,
      buttons: {
        cancel: {
          label: trans(options['cancel-text']),
          className: options['cancel-class']
        },
        confirm: {
          label: trans(options['confirm-text']),
          className: options['confirm-class'],
          callback: options.callback
        }
      }
    });
  };

  function callModuleFunctions(functionName, parameters, includeMain) {
    if (!parameters) {
      parameters = [];
    }

    for (var moduleName in bazookas) {
      if (bazookas.hasOwnProperty(moduleName)) {
        // check if module is not main!!
        var module = bazookas[moduleName];
        if ((includeMain || module !== self) && typeof module[functionName] === 'function') {
          module[functionName].apply(module, parameters);
        }
      }
    }
  }

  // when document is ready execute init functions
  $(function() {
    self.init();
  });
  return self;
})();
