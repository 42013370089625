'use strict';

bazookas.entityPicker = function () {
  var self = {},
      $currentPickerButton;

  self.init = function () {
    if (self.initialized) {
      return;
    }

    $('body').on('click', '.js-entity-picker', entityPickerClickHandler).on('click', '.js-entity-picker-item', entityPickerSelectHandler);

    $(window).on('message', messageHandler);

    self.initialized = true;
  };

  function entityPickerClickHandler(event) {
    event.preventDefault();
    var $button = $(this);
    $currentPickerButton = $button;
    var $frame = $('<iframe src="' + ($button.data('link') || $button.attr('href')) + '" class="entity-picker-frame">');

    // open the picker modal
    bootbox.hideAll();
    bootbox.dialog({
      title: $button.data('modal-title'),
      size: 'large',
      className: 'entity-picker-modal',
      message: $frame,
      backdrop: true,
      onEscape: true
    });

    $frame.on('load', frameLoadHandler);
  }

  function frameLoadHandler(event) {
    // auto set frame height when load handler is triggered
    var $frame = $(this);
    $frame.height('auto') // set to auto to make sure it's got the minimum required height
    .height($frame.contents().outerHeight() + 10) // add 10 for good measure
    ;
  }

  function messageHandler(event) {
    var data = event.originalEvent.data;
    if (data.type !== 'entityPicker') {
      return;
    }

    var entity = data.entity,
        field = $currentPickerButton.data('field'),
        $display = $(field + '_display'),
        display = entity[$currentPickerButton.data('display-field')];

    if (!display.startsWith('http') && !display.startsWith('/')) {
      display = '/' + display;
    }

    $(field).val(entity.id);

    if ($display.is('img')) {
      $display.attr('src', display);
      $display.removeClass('hide');
    } else {
      $display.val(display);
    }

    bootbox.hideAll();
  }

  function entityPickerSelectHandler(event) {
    var $item = $(this),
        entity = $item.data('entity');

    // let the parent know an entity has been picked
    window.parent.postMessage({
      type: 'entityPicker',
      entity: entity
    }, window.location.href);
  }

  return self;
}();
'use strict';

bazookas.filters = function () {
  var self = {};

  self.init = function (context) {
    bazookas.main.findInContext('.js-filter', context).initElements(initConditionallyVisible);
  };

  function initConditionallyVisible() {
    var fieldInput = $('.js-filter-field');

    fieldInput.change(filterFieldChangeHandler);
    fieldInput.change();

    var $operatorInput = $('select.js-filter-operator');
    $operatorInput.val($operatorInput.data('initially-selected'));
    $operatorInput.selectpicker('refresh');

    $('.js-filter-value').change(valueChangeHandler);
    $('.js-filter-value').change();
  }

  function filterFieldChangeHandler(event) {
    var $selected = $(event.currentTarget).find(':selected'),
        type = $selected.data('type'),
        $valueInput = $('.js-filter-value'),
        $parent = $valueInput.parent();

    switch (type) {
      case 'bool':
        if (!$parent.hasClass('form-group')) {
          $(this).parent().addClass('checkbox mar-all').removeClass('form-group');
        }
        $valueInput.prop('type', 'checkbox');

        break;
      case 'text':
      default:
        if (!$parent.hasClass('form-group')) {
          $(this).parent().addClass('form-group').removeClass('checkbox mar-all');
        }
        $valueInput.prop('type', type);
        break;
    }

    //set the options for the operators input
    var $operatorInput = $('select.js-filter-operator');
    $operatorInput.find('option').remove();
    var operators = $selected.data('operators');

    for (var key in $selected.data('operators')) {
      var option = new Option(key, operators[key]);
      $operatorInput.append($(option));
    }

    $operatorInput.selectpicker('refresh');
  }

  function valueChangeHandler(event) {
    var $selected = $('.js-filter-field').find(':selected');

    if ($selected.data('type') == 'bool') {
      if ($(event.currentTarget).is(':checked')) {
        $(event.currentTarget).val(1);
      } else {
        $(event.currentTarget).val(0);
      }
    }
  }

  return self;
}();
'use strict';

bazookas.form = function () {
  var self = {},
      language,
      now,
      conditionallyDetachedIndex = 0;

  self.init = function (context) {
    // get the language
    language = $('html').attr('lang');
    if (language === 'nl') {
      language = 'nl-BE';
    }

    // get the current date
    now = new Date();

    // TODO split into modules?

    // init date and time pickers
    bazookas.main.findInContext('.custom-datepicker .input-group.date', context).initElements(initDatePicker);
    bazookas.main.findInContext('.custom-timepicker .input-group.date', context).initElements(initTimePicker);

    bazookas.main.findInContext('form', context).initElements(initFormSubmitSpinner);

    // file previews
    bazookas.main.findInContext('[data-preview-file]', context).initElements(initFilePreview);

    // NOTE it is important that this one is executed AFTER other inits when using the conditionally-detached option
    bazookas.main.findInContext('[data-conditionally-visible]', context).initElements(initConditionallyVisible);
  };

  function initDatePicker() {
    var $target = $(this),
        $input = $target.find('input'),
        format = $input.data('format') || 'dd/mm/yyyy',
        options = $.extend({
      autoclose: true,
      format: $input.data('format') || 'dd/mm/yyyy',
      language: language,
      todayHighlight: true,
      clearBtn: true
    }, $input.data('date-picker-options') || {});

    console.log(options);
    var datepicker = $target.datepicker(options);

    datepicker.datepicker('setDate', $target.find('input').val());
  }

  function initTimePicker() {
    var $target = $(this),
        $input = $target.find('input'),
        options = $.extend({
      showMeridian: false,
      disableMousewheel: true
    }, $input.data('time-picker-options') || {});

    var timepicker = $(this).timepicker(options);
    timepicker.on('changeTime.timepicker', function (e) {
      $(this).children().val(e.time.value);
    });
    timepicker.timepicker('setTime', $(this).find('input').val());
  }

  // render a form prototype
  self.renderPrototype = function ($form, nestInto, replacementKey) {
    var prototype = $form.data('prototype'),
        count = $form.data('prototype-count'),
        prototypeName = $form.data('prototype-name') || '__name__',
        regex = new RegExp(prototypeName, 'g');

    // make nested prototypes possible
    if (nestInto) {
      prototype = nestInto.replace(replacementKey || '%childprototype%', prototype);
    }

    prototype = prototype.replace(regex, count);
    var $prototype = $(prototype);

    $form.data('prototype-count', count + 1);

    bazookas.main.init($prototype);
    return $prototype;
  };

  /**
   * Conditionally visible fields
   * add data-conditionally-visible="{'selector':'condition'}" to elements
   * where 'selector' must be a valid jquery selector
   * and 'condition' must be javascript that can be evaluated, $element in the string will be filled with the element found by the 'selector'
   * see MobilefansBundle\Form\ArticleDetailsAdminType for implementation example
   * you can add as many conditions as you like, but note conditions are handled as && for now
   **/
  function initConditionallyVisible() {
    // TODO make sure this works with multiple conditions on the same target
    var $item = $(this),
        conditions = $item.data('conditionally-visible'),
        $element = $(),
        parsedConditions = [],
        eventData = {
      conditions: parsedConditions,
      $item: $item
    };

    for (var selector in conditions) {
      if (conditions.hasOwnProperty(selector)) {
        $element = $(selector);
        $element.data('condition-dirty', true);
        parsedConditions.push({
          $element: $element,
          isTrue: false,
          condition: conditions[selector]
        });

        $element.on('input change', eventData, conditionallyVisibleInputHandler);
      }
    }

    // trigger the event on the last element, the handler will loop over conditions anyway
    // but wait a little while to make sure everything is initialized on the child elements
    setTimeout(function () {
      $element.trigger('change');
    }, 100);
  }

  function conditionallyVisibleInputHandler(event) {
    var $target = $(this),
        conditions = event.data.conditions,
        isTrue = true,
        $item = event.data.$item;

    $target.data('condition-dirty', true);
    for (var i = 0; i < conditions.length; i++) {
      var condition = conditions[i],
          $element = condition.$element;
      if ($element.data('condition-dirty')) {
        // do the check
        var $value = $element.val();

        /* jshint ignore:start */
        condition.isTrue = eval(condition.condition);
        /* jshint ignore:end */

        $element.data('condition-dirty', false);
      }

      isTrue = condition.isTrue;
      if (!isTrue) {
        // if the first "false" element is found no need to check the rest
        // if they have a dirty flag they will be checked later
        break;
      }
    }

    if ($item.data('conditionally-detached')) {
      var $itemPlaceholder = $item.data('conditional-placeholder');
      if (!$itemPlaceholder) {
        $itemPlaceholder = $('<div id="conditionally-detached-placeholder-' + conditionallyDetachedIndex++ + '">');
        $itemPlaceholder.insertBefore($item);
        $item.data('conditional-placeholder', $itemPlaceholder);
      }

      if (isTrue) {
        $item.insertAfter($itemPlaceholder);
      } else {
        $item.detach();
      }
    } else {
      if (isTrue) {
        $item.show();
      } else {
        $item.hide();
      }
    }
  }

  function initFilePreview() {
    var $preview = $(this),
        $input = $($preview.data('preview-file'));

    // handle file change event
    // TODO cross browser check, this will only work in "real" browsers now
    $input.on('change', function () {
      var input = this;
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (loadEvent) {
          $preview.attr('src', loadEvent.target.result);
          $preview.removeClass('hide');
        };

        reader.readAsDataURL(input.files[0]);
      }
    });
  }

  function initFormSubmitSpinner() {
    var $form = $(this);
    $form.on('submit', formSubmitHandler);
  }

  function formSubmitHandler() {
    var $form = $(this),
        $button = $form.find('[type="submit"]');
    $button.prop('disabled', true).find('.js-spinner').removeClass('hidden');
  }

  return self;
}();
'use strict';

bazookas.general = function () {
  var self = {};

  self.init = function (context) {
    if (!context) {
      $('body').on('click', '.js-confirm-navigation', confirmNavigationClickHandler);
    }
  };

  /**
  * prevents default behaviour of a link and shows a confirm dialog
  * options can be overridden by data attributes on clicked button
  **/
  function confirmNavigationClickHandler(event) {
    var $link = $(this),
        options = {
      message: 'dialog.confirmRemoveMessage',
      'message-replacements': { '%element%': 'dialog.thisItem' },
      'confirm-text': 'dialog.confirm',
      'confirm-class': 'btn-danger',
      'cancel-text': 'dialog.cancel'
    };

    // extend the options object with the data from the clicked button
    // this way you can add something like: data-confirm-color="success"
    // and it will end up in the options object
    options = $.extend(options, $link.data());
    options.callback = function () {
      window.location.href = $link.attr('href');
    };

    bazookas.main.showConfirmDialog(options);

    // prevent default navigation
    return false;
  }

  return self;
}();
'use strict';

// small jquery plugin to call init functions
// but only call them once
// this way we don't need to check in every loop again
$.fn.initElements = function (initFunction) {
  if (typeof initFunction !== 'function') {
    return this;
  }

  for (var i = 0; i < this.length; i++) {
    var $element = $(this[i]);
    var initialized = $element.data('initialized') || [];
    if (initialized.indexOf(initFunction) > -1) {
      // continue to the next element, this one is already done
      continue;
    }

    initFunction.call($element, i, $element);
    initialized.push(initFunction);
    $element.data('initialized', initialized);
  }

  // allow chaining
  return this;
};
'use strict';

/**
 * SIMPLE JS MODULE SYSTEM
 **/

// NOTE no need to define namespace in this project, I've made sure it is defined as the very first thing
// var bazookas = bazookas || {};

// then create the module itself
bazookas.main = function () {
  // a module is basically a self executing function
  // returning an object with properties or methods
  var self = {};

  /**
   * Init function to do first setup or refresh parts of the page
   * @param DOM|jQuery the context in which to execute the init functions, this has no effect on the main module, but submodules should use this
   **/
  self.init = function (context) {
    // get all modules and execute the init functions
    callModuleFunctions('init', [context]);
  };

  // find elements in given context
  self.findInContext = function (selector, context) {
    if (context) {
      return $(context).find(selector);
    } else {
      return $(selector);
    }
  };
  // TODO self.initInContext = function (selector, context, callback) to replace inits in modules

  self.showConfirmDialog = function (options) {
    options = $.extend({
      message: 'dialog.confirmRemoveMessage',
      'message-replacements': 'dialog.thisItem',
      'confirm-text': 'dialog.confirm',
      'confirm-class': 'btn-danger',
      'cancel-text': 'dialog.cancel',
      'cancel-class': 'btn-default',
      callback: null
    }, options);
    bootbox.dialog({
      message: trans('dialog.confirmRemoveMessage', options['message-replacements']),
      title: trans('dialog.areYouSure', options['message-replacements']),
      backdrop: true,
      buttons: {
        cancel: {
          label: trans(options['cancel-text']),
          className: options['cancel-class']
        },
        confirm: {
          label: trans(options['confirm-text']),
          className: options['confirm-class'],
          callback: options.callback
        }
      }
    });
  };

  function callModuleFunctions(functionName, parameters, includeMain) {
    if (!parameters) {
      parameters = [];
    }

    for (var moduleName in bazookas) {
      if (bazookas.hasOwnProperty(moduleName)) {
        // check if module is not main!!
        var module = bazookas[moduleName];
        if ((includeMain || module !== self) && typeof module[functionName] === 'function') {
          module[functionName].apply(module, parameters);
        }
      }
    }
  }

  // when document is ready execute init functions
  $(function () {
    self.init();
  });
  return self;
}();
'use strict';

bazookas.nestedForm = function () {
  var self = {
    init: function init(context) {
      bazookas.main.findInContext('.js-nested-form', context).initElements(initForm);
    }
  };

  function initForm(index, $form) {
    var formID = $form.attr('id');
    if ($form.data('allow-add')) {
      var $addButton = $('<span class="btn btn-info js-add-button">' + trans('general.add') + '</span>');
      $addButton.data('form', $form).on('click', addClickHandler).insertAfter($form);
    }

    if ($form.data('sortable')) {
      // List with handle
      Sortable.create($form[0], {
        handle: '.js-sortable-item-handle',
        animation: 150
      });
      $form.on('sort', formSortHandler);

      // REVIEW is this needed? When form is rendered for the first time shouldn't sort weights be correctly set already (backend stuff)?
      updateSortWeights($form);
    }

    $form.on('click', '.js-remove-nested-item', removeClickHandler);
  }

  function formSortHandler() {
    updateSortWeights($(this));
  }

  function updateSortWeights($form) {
    if ($form.data('sortable')) {
      var $items = $form.find('.js-nested-form-item');
      for (var i = 0; i < $items.length; i++) {
        $('#' + $($items[i]).data('sortkey')).val(i);
      }
    }
  }

  function addClickHandler(event) {
    var $button = $(this),
        $form = $button.data('form'),
        $prototype = bazookas.form.renderPrototype($form, $form.data('item-container'));

    $prototype.appendTo($form);
    updateSortWeights($form);
  }

  function removeClickHandler(e) {
    e.stopPropagation();
    var $button = $(this);
    bazookas.main.showConfirmDialog({
      'message-replacements': { '%element%': 'dialog.thisItem' },
      callback: function callback() {
        var $form = $button.closest('.js-nested-form');
        $button.closest('.js-nested-form-item').remove();

        // NOTE technically, there is no real need to update the sort weights when deleting
        // because gaps don't prevent correct sorting
        // but it's a little cleaner if sort weight doesn't have gaps
        updateSortWeights($form);
      }
    });
  }

  return self;
}();
'use strict';

bazookas.tooltips = function () {
  var self = {};

  self.init = function (context) {
    bazookas.main.findInContext('.add-tooltip', context).initElements(initTooltip);
    bazookas.main.findInContext('.add-popover', context).initElements(initPopover);
  };

  function initTooltip() {
    $(this).tooltip();
  }

  function initPopover() {
    $(this).popover();
  }

  return self;
}();
'use strict';

bazookas.translationForm = function () {
  var self = {
    init: function init(context) {
      bazookas.main.findInContext('.js-translation-form', context).initElements(initForm);
    }
  };

  function initForm(index, $form) {
    var requireAllLocales = $form.data('require-all-locales');
    var formID = $form.attr('id');

    intitializeOriginal($form, formID, requireAllLocales);
    initializeTabs($form, formID, requireAllLocales);

    // // add event listeners
    $form.on('click', '.js-remove-translation', removeClickHandler);
    if ($form.data('locale-count') < 2) {
      // NOTE because nifty completely fucked up the bootstrap grid system, don't addClass('col-sm-12') to the next line
      var $firstColumn = $form.find('.js-translation-form__main-column').removeClass('col-sm-6');
      $firstColumn.find('.translation-form__header').addClass('hidden');
      $firstColumn.next().addClass('hidden');
    }
  }

  // INTIALIZE THE "ORIGINAL" LANGUAGE (left panel)
  function intitializeOriginal($form, formID, requireAllLocales) {
    var $firstItem = $form.find('.js-translation-form__first');
    if ($form.data('prototype-count') === 0) {
      // create the first one
      // {% set delete_key = form.vars.id|replace({'form_': 'delete_'}) ~ "_" ~ index %}
      var $prototype = bazookas.form.renderPrototype($form);
      $('<div id="' + formID + '__translation-form__tab-0" class="js-translation-form__item">').append($prototype).appendTo($firstItem);

      // select the default locale
      var defaultLocale = $form.data('default-locale'),
          $defaultLocale = $prototype.find('[id$="_locale"]');
      $defaultLocale.find('option').each(function () {
        var $option = $(this);
        if ($option.val() === defaultLocale || $option.text() === defaultLocale) {
          $defaultLocale.val($option.val());
          return false;
        }
      });
    }

    var $locale = $firstItem.find('[id$="_locale"]'),
        currentLocale = $locale.val(),
        locales = {},
        localeLabels = {},
        localeCount = 0;

    $locale.find('option').each(function () {
      var locale = $(this).val();
      locales[locale] = currentLocale === locale;
      localeLabels[locale] = $(this).text();
      localeCount++;
    });

    //only show the locale drop down if not all of the locales are required
    if (requireAllLocales) {
      $locale.hide();
      $locale.parent('.input-group').hide();
    } else {
      $locale.on('change', changeMainLanguageHandler);
    }

    // TODO make data object that contains all translation properties
    // this will reduce lines below and make it harder for clashing data names
    $form.data('main-locale', currentLocale);
    $form.data('locales', locales);
    $form.data('localeLabels', localeLabels);
    $form.data('localeCount', localeCount);

    //TODO can we solve this by just adding a class? CAN WEEEE??? find out on the next episode of "web team goes wild"...
    // $locale.addClass('selectpicker');
    $form.find('.js-translation-form__main-locale').append($locale);
    $locale.selectpicker();
  }

  function initializeTabs($form, formID, requireAllLocales) {
    var barID = formID + '__tab-bar',
        contentID = formID + '__tab-content',
        $tabBar = $('#' + barID),
        $tabContent = $('#' + contentID),
        locales = $form.data('locales'),
        localeLabels = $form.data('localeLabels');
    if ($tabBar.length === 0) {
      // if no tabBar is found, neither is the content
      $tabBar = $('<ul id="' + barID + '" role="tablist" class="nav nav-tabs">');
      $tabContent = $('<div id="' + contentID + '" class="js-translation-form__tab-content tab-content">');

      $('<div class="col-sm-6">').append($tabBar).append($tabContent).appendTo($form);
    }

    $tabContent.find('.js-translation-form__item').each(function (index, element) {
      var $tabContent = $(this);

      // hide the language dropdown
      var $locale = $tabContent.find('[id$="_locale"]');
      var locale = $locale.val();
      locales[locale] = true;
      $locale.hide();

      // add the tab to the tabBar
      var $tab = addTab(formID, $tabContent, locale, localeLabels[locale], $tabBar, requireAllLocales);

      if (index === 0) {
        $tab.addClass('active');
      } else {
        $tabContent.removeClass('active');
      }
    });
    if (!requireAllLocales) {
      createLanguageDropdown($form, formID, $tabBar);
    } else {
      for (var locale in locales) {
        if (locales.hasOwnProperty(locale) && !locales[locale]) {
          createLanguageTabContent(formID, $form, $tabContent, locales, locale, $tabBar, requireAllLocales);
        }
      }
    }
  }

  function createLanguageDropdown($form, formID, $tabBar) {
    var tab = '<li id="' + formID + '__add-language-picker" role="presentation" class="js-add-language-picker dropdown">';
    tab += '<a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">' + $form.data('add-translation-text') + '</a>';
    tab += '<ul class="dropdown-menu">';

    var locales = $form.data('locales'),
        localeLabels = $form.data('localeLabels');
    for (var locale in locales) {
      if (locales.hasOwnProperty(locale)) {
        var used = locales[locale];
        tab += '<li class="' + (used ? 'hidden' : '') + ' js-add-language-option-' + locale + '"><a class="js-add-language"';
        tab += 'href="#' + locale + '" data-locale="' + locale + '">' + localeLabels[locale] + '</a></li>';
      }
    }

    tab += '</ul>';
    var $tab = $(tab);
    $tab.on('click', 'a.js-add-language', addLanguageClickHandler);

    $tabBar.append($tab);
  }

  // add a tab to the tabbar and link it to the correct tab
  function addTab(formID, $tabContent, locale, localeLabel, $tabBar, requireAllLocales) {
    var tabID = $tabContent.attr('id');

    var removeButton = ' <span class="required">*</span>';
    if (!requireAllLocales) {
      removeButton = '&nbsp;&nbsp;<i class="js-remove-translation btn btn-danger btn-xs btn-circle ti-close"></span>';
    }

    var $tab = $('<li role="presentation"><a href="#' + tabID + '" aria-controls="' + tabID + '" role="tab" data-toggle="tab">' + localeLabel + removeButton + '</a></li>');

    $tabBar.append($tab);
    return $tab;
  }

  // update the languages visible in the language dropdown
  function updateLanguageDropdown($form, locales) {
    locales = locales || $form.data('locales');
    var $mainLanguagePicker = $form.find('.js-translation-form__main-locale select'),
        mainLocale = $form.data('main-locale');
    for (var locale in locales) {
      if (locales.hasOwnProperty(locale)) {
        var $item = $form.find('.js-add-language-option-' + locale);
        $mainLanguagePicker.find('option[value="' + locale + '"]').prop('disabled', locales[locale] && locale !== mainLocale);
        if (locales[locale]) {
          $item.addClass('hidden');
        } else {
          $item.removeClass('hidden');
        }
      }
    }
  }

  // event listeners
  function changeMainLanguageHandler(event) {
    var $select = $(this),
        currentLocale = $select.val(),
        $form = $select.closest('.js-translation-form'),
        formID = $form.attr('id'),
        previousLocale = $form.data('main-locale'),
        locales = $form.data('locales');

    locales[previousLocale] = false;
    locales[currentLocale] = true;
    $form.data('main-locale', currentLocale);
    updateLanguageDropdown($form, locales);
  }

  function addLanguageClickHandler(event) {
    var $localeLink = $(this),
        $form = $localeLink.closest('.js-translation-form'),
        $dropdown = $localeLink.closest('.js-add-language-picker'),
        selectedLocale = $localeLink.data('locale'),
        locales = $form.data('locales'),
        formID = $form.attr('id'),
        index = $form.data('prototype-count'),
        $tabContent = $('#' + formID + '__tab-content'),
        $tabBar = $('#' + formID + '__tab-bar');

    // create the tab content
    createLanguageTabContent(formID, $form, $tabContent, locales, selectedLocale, $tabBar);

    // move dropdown to the end of the list
    $tabBar.append($dropdown);
  }

  function createLanguageTabContent(formID, $form, $tabContent, locales, selectedLocale, $tabBar, requireAllLocales) {
    var index = $form.data('prototype-count'),
        localeLabels = $form.data('localeLabels'),


    // create the tab content
    $tabPane = $('<div id="' + formID + '__translation-form__tab-' + index + '" class="js-translation-form__item tab-pane">'),
        $prototype = bazookas.form.renderPrototype($form);
    $tabPane.append($prototype).appendTo($tabContent);

    // set the locale
    var $locale = $tabPane.find('[id$="_locale"]');
    $locale.val(selectedLocale).hide();

    // create a new tab in the tabbar
    var $tab = addTab(formID, $tabPane, selectedLocale, localeLabels[selectedLocale], $tabBar, requireAllLocales);

    // make the tab active
    $tab.find('a').trigger('click');

    //set the locale as used
    locales[selectedLocale] = true;
    updateLanguageDropdown($form, locales);
  }

  function removeClickHandler(event) {
    var $button = $(this);
    bazookas.main.showConfirmDialog({
      'message-replacements': { '%element%': 'dialog.thisTranslation' },
      callback: function callback() {
        removeTabConfirmHandler($button.closest('.js-translation-form'), $button.closest('[role="tab"]').attr('href'));
      }
    });
  }

  function removeTabConfirmHandler($form, deleteTarget) {
    var $tabPane = $(deleteTarget),
        $tab = $form.find('[href="' + deleteTarget + '"]'),
        removedLanguage = $tabPane.find('[id$="_locale"]').val(),
        wasActive = $tabPane.hasClass('active');

    // Remove item
    $tabPane.remove();
    $tab.closest('li').remove();

    if (wasActive) {
      $form.find('#' + $form.attr('id') + '__tab-bar').find('li:first a[role="tab"]').trigger('click');
    }

    var locales = $form.data('locales');
    locales[removedLanguage] = false;
    updateLanguageDropdown($form, locales);
  }

  return self;
}();
'use strict';

bazookas.youtubeUploader = function () {
  var self = {};
  var STATUS_POLLING_INTERVAL_MILLIS = 10 * 1000; // ten seconds.
  var statusPollTimeout;

  self.init = function (context) {
    bazookas.main.findInContext('.js-youtube-modal', context).initElements(initYoutubeModal);
  };

  window.youtubeUploaderSigninCallback = self.signinCallback = function (result) {
    // show upload form

    // TODO http://stackoverflow.com/questions/38751262/gapi-auth2-can-not-get-access-token
    // if (result.isSignedIn()) {
    //   self.user = result;
    //   showUploadForm();
    // }
    if (result.status.signed_in && result.access_token) {
      showUploadForm();
    }
  };

  function showUploadForm() {
    var $modal = $('.js-youtube-modal');

    gapi.client.request({
      path: '/youtube/v3/channels',
      params: {
        part: 'snippet',
        mine: true
      },
      callback: function callback(response) {
        if (response.error) {
          console.log(response.error.message);
          alert('Unable to retrieve channel');
        } else {
          $modal.find('.js-channel-name').text(response.items[0].snippet.title);
          $modal.find('.js-channel-thumbnail').attr('src', response.items[0].snippet.thumbnails.default.url);

          hide($modal.find('.js-sign-in'));
          show($modal.find('.js-upload-form, .js-upload-button, .js-upload-disclaimer'));
        }
      }
    });
  }

  // init user click handlers
  function initYoutubeModal() {
    var $modal = $(this);
    $modal.on('show.bs.modal', modalShowHandler).on('hide.bs.modal', modalHideHandler);
    $modal.find('.js-upload-button').on('click', uploadClickHandler);
    $modal.find('.js-confirm-button').on('click', confirmClickHandler);
  }

  function modalShowHandler(event) {
    var $button = $(event.relatedTarget);
    $(this).find('.js-confirm-button').data('target-field', $button.data('target-field'));
  }
  function modalHideHandler(event) {
    // TODO cancel uploads!!

    clearTimeout(statusPollTimeout);
    resetView($(this));
  }
  function resetView($modal) {
    $modal.find('.js-upload-button').prop('disabled', false);
    show($modal.find('.js-upload-form, .js-upload-button'));
    hide($modal.find('.js-confirm-button, .js-upload-complete, .js-upload-progress'));
    $modal.find('.js-post-upload-status').html('');
  }

  function uploadClickHandler() {
    var $button = $(this).attr('disabled', true),
        $modal = $button.closest('.js-youtube-modal'),
        $form = $modal.find('.js-upload-form'),
        $progressContainer = $modal.find('.js-upload-progress'),
        metadata = {};

    show($progressContainer);
    hide($modal.find('.js-upload-form'));

    metadata.snippet = {
      title: $form.find('.js-title-input').val(),
      description: $form.find('.js-description-input').val(),
      tags: [], // can be array of strings
      categoryId: 17 // video category 17 is sports
    };
    metadata.status = {
      privacyStatus: "public",
      embeddable: true,
      license: 'youtube'
    };
    uploadFile($form.find('.js-file-input').get(0).files[0], metadata, uploadCompleteHandler.bind($modal), $progressContainer);
  }

  function uploadFile(file, metadata, completeHandler, $progressContainer) {
    var $progress = $progressContainer.find('.progress'),
        $percent = $progressContainer.find('.js-percent-transferred'),
        $bytes = $progressContainer.find('.js-bytes-transferred'),
        $bytesTotal = $progressContainer.find('.js-bytes-total');

    var uploader = new MediaUploader({
      baseUrl: 'https://www.googleapis.com/upload/youtube/v3/videos',
      file: file,
      token: gapi.auth.getToken().access_token, // TODO http://stackoverflow.com/questions/38751262/gapi-auth2-can-not-get-access-token
      metadata: metadata,
      params: {
        part: 'snippet,status,contentDetails'
      },
      onError: uploadErrorHandler,
      onProgress: uploadProgressHandler.bind($progressContainer, $progress, $percent, $bytes, $bytesTotal),
      onComplete: completeHandler
    });
    // This won't correspond to the *exact* start of the upload, but it should be close enough.
    // this.uploadStartTime = Date.now();
    uploader.upload();
  }

  function uploadErrorHandler(data) {
    var message = data;
    // Assuming the error is raised by the YouTube API, data will be
    // a JSON string with error.message set. That may not be the
    // only time onError will be raised, though.
    try {
      var errorResponse = JSON.parse(data);
      message = errorResponse.error.message;
    } finally {
      alert(message);
    }
  }

  function uploadProgressHandler($progress, $percent, $bytes, $bytesTotal, data) {
    // var currentTime = Date.now();
    var bytesUploaded = data.loaded;
    var bytesTotal = data.total;
    // The times are in millis, so we need to divide by 1000 to get seconds.
    var percentageComplete = Math.round(bytesUploaded * 100 / bytesTotal);
    // var bytesPerSecond = bytesUploaded / ((currentTime - this.uploadStartTime) / 1000);
    // var estimatedSecondsRemaining = (bytesTotal - bytesUploaded) / bytesPerSecond;

    $progress.children('.progress-bar').width(percentageComplete + '%');

    $percent.text(percentageComplete);
    $bytes.text(bytesUploaded);
    $bytesTotal.text(bytesTotal);
  }

  function uploadCompleteHandler(data) {
    var $modal = $(this),
        $complete = $modal.find('.js-upload-complete'),
        $confirm = $modal.find('.js-confirm-button'),
        $statusList = $modal.find('.js-post-upload-status'),
        uploadResponse = JSON.parse(data);

    hide($modal.find('.js-upload-progress, .js-upload-button'));
    show($complete);
    show($confirm);

    var videoUrl = 'https://www.youtube.com/watch?v=' + uploadResponse.id;
    $confirm.data('video-url', videoUrl);
    $complete.find('.js-video-id').html('<a class="text-primary" href="' + videoUrl + '">' + videoUrl + '</a>');
    pollForVideoStatus.call($statusList, uploadResponse.id);
  }

  function pollForVideoStatus(videoId) {
    var $statusList = this;
    // add a spinner to the list to make users believe something is happening
    var $spinner = $statusList.find('li.js-spinner');
    if ($spinner.length === 0) {
      $spinner = $('<li class="js-spinner"><i class="fa fa-refresh fa-spin"></i></li>');
    }
    $statusList.append($spinner);

    gapi.client.request({
      path: '/youtube/v3/videos',
      params: {
        // part: 'status,player',
        part: 'status',
        id: videoId
      },
      callback: function callback(response) {
        // TODO translate status codes
        if (response.error) {
          // The status polling failed.
          console.log('status call failed', response);
          $statusList.append('<li>' + response.error.message + '</li>');
          statusPollTimeout = setTimeout(pollForVideoStatus.bind($statusList, videoId), STATUS_POLLING_INTERVAL_MILLIS);
        } else {
          var status = response.items[0].status;
          var uploadStatus = status.uploadStatus;

          // remove in case the status is final
          $spinner.remove();

          switch (uploadStatus) {
            // The video was successfully transcoded and is available.
            case 'processed':
              // $('#player').append(response.items[0].player.embedHtml);
              $statusList.append('<li class="text-success">Done! Video processed and ready!</li>');
              break;
            // This is a non-final status, so we need to poll again.
            case 'uploaded':
              $statusList.append('<li class="text-info">Upload status: ' + uploadStatus + '</li>');
              $statusList.append($spinner);
              statusPollTimeout = setTimeout(pollForVideoStatus.bind($statusList, videoId), STATUS_POLLING_INTERVAL_MILLIS);
              break;
            // BELOW ARE ALL FAILURES
            case 'deleted':
              $statusList.append('<li class="text-danger">This video was deleted</li>');
              break;
            case 'failed':
              $statusList.append('<li class="text-danger">Failed: ' + status.failureReason + '</li>');
              break;
            case 'rejected':
              $statusList.append('<li class="text-danger">Video rejected: ' + status.rejectionReason + '</li>');
              break;
            // All other statuses indicate a permanent transcoding failure.
            default:
              $statusList.append('<li class="text-danger">Video upload failed: ' + uploadStatus + '</li>');
              break;
          }
        }
      }
    });
  }

  function confirmClickHandler() {
    var $button = $(this);
    $($button.data('target-field')).val($button.data('video-url'));
  }

  function show($element) {
    $element.removeClass('hidden');
  }
  function hide($element) {
    $element.addClass('hidden');
  }

  return self;
}();
'use strict';

/*
Copyright 2015 Google Inc. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

  http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

var DRIVE_UPLOAD_URL = 'https://www.googleapis.com/upload/drive/v2/files/';

/**
 * Helper for implementing retries with backoff. Initial retry
 * delay is 1 second, increasing by 2x (+jitter) for subsequent retries
 *
 * @constructor
 */
var RetryHandler = function RetryHandler() {
  this.interval = 1000; // Start at one second
  this.maxInterval = 60 * 1000; // Don't wait longer than a minute
};

/**
 * Invoke the function after waiting
 *
 * @param {function} fn Function to invoke
 */
RetryHandler.prototype.retry = function (fn) {
  setTimeout(fn, this.interval);
  this.interval = this.nextInterval_();
};

/**
 * Reset the counter (e.g. after successful request.)
 */
RetryHandler.prototype.reset = function () {
  this.interval = 1000;
};

/**
 * Calculate the next wait time.
 * @return {number} Next wait interval, in milliseconds
 *
 * @private
 */
RetryHandler.prototype.nextInterval_ = function () {
  var interval = this.interval * 2 + this.getRandomInt_(0, 1000);
  return Math.min(interval, this.maxInterval);
};

/**
 * Get a random int in the range of min to max. Used to add jitter to wait times.
 *
 * @param {number} min Lower bounds
 * @param {number} max Upper bounds
 * @private
 */
RetryHandler.prototype.getRandomInt_ = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Helper class for resumable uploads using XHR/CORS. Can upload any Blob-like item, whether
 * files or in-memory constructs.
 *
 * @example
 * var content = new Blob(["Hello world"], {"type": "text/plain"});
 * var uploader = new MediaUploader({
 *   file: content,
 *   token: accessToken,
 *   onComplete: function(data) { ... }
 *   onError: function(data) { ... }
 * });
 * uploader.upload();
 *
 * @constructor
 * @param {object} options Hash of options
 * @param {string} options.token Access token
 * @param {blob} options.file Blob-like item to upload
 * @param {string} [options.fileId] ID of file if replacing
 * @param {object} [options.params] Additional query parameters
 * @param {string} [options.contentType] Content-type, if overriding the type of the blob.
 * @param {object} [options.metadata] File metadata
 * @param {function} [options.onComplete] Callback for when upload is complete
 * @param {function} [options.onProgress] Callback for status for the in-progress upload
 * @param {function} [options.onError] Callback if upload fails
 */
var MediaUploader = function MediaUploader(options) {
  var noop = function noop() {};
  this.file = options.file;
  this.contentType = options.contentType || this.file.type || 'application/octet-stream';
  this.metadata = options.metadata || {
    'title': this.file.name,
    'mimeType': this.contentType
  };
  this.token = options.token;
  this.onComplete = options.onComplete || noop;
  this.onProgress = options.onProgress || noop;
  this.onError = options.onError || noop;
  this.offset = options.offset || 0;
  this.chunkSize = options.chunkSize || 0;
  this.retryHandler = new RetryHandler();

  this.url = options.url;
  if (!this.url) {
    var params = options.params || {};
    params.uploadType = 'resumable';
    this.url = this.buildUrl_(options.fileId, params, options.baseUrl);
  }
  this.httpMethod = options.fileId ? 'PUT' : 'POST';
};

/**
 * Initiate the upload.
 */
MediaUploader.prototype.upload = function () {
  var xhr = new XMLHttpRequest();

  xhr.open(this.httpMethod, this.url, true);
  xhr.setRequestHeader('Authorization', 'Bearer ' + this.token);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader('X-Upload-Content-Length', this.file.size);
  xhr.setRequestHeader('X-Upload-Content-Type', this.contentType);

  xhr.onload = function (e) {
    if (e.target.status < 400) {
      var location = e.target.getResponseHeader('Location');
      this.url = location;
      this.sendFile_();
    } else {
      this.onUploadError_(e);
    }
  }.bind(this);
  xhr.onerror = this.onUploadError_.bind(this);
  xhr.send(JSON.stringify(this.metadata));
};

/**
 * Send the actual file content.
 *
 * @private
 */
MediaUploader.prototype.sendFile_ = function () {
  var content = this.file;
  var end = this.file.size;

  if (this.offset || this.chunkSize) {
    // Only bother to slice the file if we're either resuming or uploading in chunks
    if (this.chunkSize) {
      end = Math.min(this.offset + this.chunkSize, this.file.size);
    }
    content = content.slice(this.offset, end);
  }

  var xhr = new XMLHttpRequest();
  xhr.open('PUT', this.url, true);
  xhr.setRequestHeader('Content-Type', this.contentType);
  xhr.setRequestHeader('Content-Range', 'bytes ' + this.offset + '-' + (end - 1) + '/' + this.file.size);
  xhr.setRequestHeader('X-Upload-Content-Type', this.file.type);
  if (xhr.upload) {
    xhr.upload.addEventListener('progress', this.onProgress);
  }
  xhr.onload = this.onContentUploadSuccess_.bind(this);
  xhr.onerror = this.onContentUploadError_.bind(this);
  xhr.send(content);
};

/**
 * Query for the state of the file for resumption.
 *
 * @private
 */
MediaUploader.prototype.resume_ = function () {
  var xhr = new XMLHttpRequest();
  xhr.open('PUT', this.url, true);
  xhr.setRequestHeader('Content-Range', 'bytes */' + this.file.size);
  xhr.setRequestHeader('X-Upload-Content-Type', this.file.type);
  if (xhr.upload) {
    xhr.upload.addEventListener('progress', this.onProgress);
  }
  xhr.onload = this.onContentUploadSuccess_.bind(this);
  xhr.onerror = this.onContentUploadError_.bind(this);
  xhr.send();
};

/**
 * Extract the last saved range if available in the request.
 *
 * @param {XMLHttpRequest} xhr Request object
 */
MediaUploader.prototype.extractRange_ = function (xhr) {
  var range = xhr.getResponseHeader('Range');
  if (range) {
    this.offset = parseInt(range.match(/\d+/g).pop(), 10) + 1;
  }
};

/**
 * Handle successful responses for uploads. Depending on the context,
 * may continue with uploading the next chunk of the file or, if complete,
 * invokes the caller's callback.
 *
 * @private
 * @param {object} e XHR event
 */
MediaUploader.prototype.onContentUploadSuccess_ = function (e) {
  if (e.target.status == 200 || e.target.status == 201) {
    this.onComplete(e.target.response);
  } else if (e.target.status == 308) {
    this.extractRange_(e.target);
    this.retryHandler.reset();
    this.sendFile_();
  }
};

/**
 * Handles errors for uploads. Either retries or aborts depending
 * on the error.
 *
 * @private
 * @param {object} e XHR event
 */
MediaUploader.prototype.onContentUploadError_ = function (e) {
  if (e.target.status && e.target.status < 500) {
    this.onError(e.target.response);
  } else {
    this.retryHandler.retry(this.resume_.bind(this));
  }
};

/**
 * Handles errors for the initial request.
 *
 * @private
 * @param {object} e XHR event
 */
MediaUploader.prototype.onUploadError_ = function (e) {
  this.onError(e.target.response); // TODO - Retries for initial upload
};

/**
 * Construct a query string from a hash/object
 *
 * @private
 * @param {object} [params] Key/value pairs for query string
 * @return {string} query string
 */
MediaUploader.prototype.buildQuery_ = function (params) {
  params = params || {};
  return Object.keys(params).map(function (key) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
  }).join('&');
};

/**
 * Build the drive upload URL
 *
 * @private
 * @param {string} [id] File ID if replacing
 * @param {object} [params] Query parameters
 * @return {string} URL
 */
MediaUploader.prototype.buildUrl_ = function (id, params, baseUrl) {
  var url = baseUrl || DRIVE_UPLOAD_URL;
  if (id) {
    url += id;
  }
  var query = this.buildQuery_(params);
  if (query) {
    url += '?' + query;
  }
  return url;
};
'use strict';

bazookas.listOrderable = function () {
  var self = {},
      sortedTable;

  self.init = function (context) {
    bazookas.main.findInContext('.js-table-orderable tbody', context).initElements(initOrderable);
  };

  function initOrderable() {
    var $table = $(this);

    sortedTable = Sortable.create($table[0], {
      handle: '.js-orderable-handle',
      draggable: 'tr',
      animation: 250,
      onSort: updateOrder
    });
  }

  function updateOrder(event) {
    //temporarily change the background of the moved item
    var $originalElement = $(event.item);
    $originalElement.addClass('bg-primary');

    setTimeout(function () {
      $originalElement.removeClass('bg-primary');
    }, 1500);

    $('.js-orderable-displayField').each(function (index) {
      var $this = $(this);

      $this.data('previous-order', $this.data('order'));
      $this.data('order', index + 1);
      $this.text(index + 1);
    });

    //temporarily disable sorting
    sortedTable.option("disabled", true);

    $('.js-table-orderable tbody').addClass('drag-disabled');

    updateEntities();
  }

  function updateEntities() {
    var data = [];

    $('.js-orderable-displayField').each(function () {
      data.push({ 'id': $(this).data('id'), 'order': $(this).data('order') });
    });

    $.ajax({
      type: "POST",
      url: $('.js-table-orderable').data('url'),
      data: JSON.stringify(data),
      dataType: "json",
      success: function success(response) {
        //reenable sorting
        sortedTable.option("disabled", false);
        $('.js-table-orderable tbody').removeClass('drag-disabled');

        //send a notification!
        notify('success', trans('orderable.success'));
      },
      failure: function failure(response) {
        notify('success', trans('orderable.error'));
      }
    });
  }

  function notify(type, message) {
    $.niftyNoty({
      type: type != 'success' ? 'danger' : 'success',
      title: type != 'success' ? 'Error' : 'Success',
      message: message,
      container: 'floating',
      timer: 3000
    });
  }

  return self;
}();
'use strict';

bazookas.lessonPlan = function () {
  var self = {},
      freeChecked = false,
      ignoreChange = true;

  self.init = function (context) {
    bazookas.main.findInContext('#lesson_plan_admin_free', context).initElements(setCheckedListener);

    bazookas.main.findInContext('.js-free-check', context).initElements(setVideoFreeChecked);

    ignoreChange = false;
  };

  function setCheckedListener() {
    var $target = $(this);
    $target.on('change', freeCheckedHandler);
    freeChecked = $target.is(':checked');
  }

  function setVideoFreeChecked() {
    var $target = $(this);
    $target.on('change', changeSingleCheck);
    if (!ignoreChange) {
      $target.prop('checked', freeChecked);
    }
  }

  function changeSingleCheck(event) {
    var $target = $(event.target),
        checked = $target.is(':checked');
    if (!checked) {
      freeChecked = false;
      ignoreChange = true;
      $('#lesson_plan_admin_free').prop('checked', false);
      ignoreChange = false;
    }
  }

  function freeCheckedHandler(event) {
    var $target = $(event.target);
    freeChecked = $target.is(':checked');
    if (!ignoreChange) {
      $('.js-free-check').prop('checked', freeChecked);
    }
  }

  return self;
}();
'use strict';

bazookas.libraries = function () {
  var self = {},
      language,
      now;
  self.init = function (context) {
    bazookas.main.findInContext('.js-icon-picker', context).initElements(setIconPickerSet);
  };

  function setIconPickerSet() {
    $(this).iconpicker({
      iconset: {
        iconClass: 'yme',
        iconClassFix: 'evycon-',
        icons: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'a', 'b', 'c', 'd', 'e', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
      }
    });
  }

  return self;
}();
'use strict';

bazookas.adminSelect = function () {
  var self = {};
  self.init = function (context) {
    bazookas.main.findInContext('.js-select-all', context).initElements(initSelectAll);
  };

  function initSelectAll() {
    //Add a hidden select all checkbox
    var $target = $(this);
    $target.prepend($('<div id="js-select-all-container" class="checkbox mar-btm"><label class="mar-btm" style="font-weight: 700;"><input class="magic-checkbox js-select-all-checkbox" type="checkbox">' + trans('admin.entities.voucher.form.selectAll') + '</label></div>'));
    $target.on('click', '.js-select-all-checkbox', selectAllHandler);
  }

  function selectAllHandler(event) {
    var $target = $(event.target);
    var $parent = $target.closest('.js-select-all');

    if ($target.is(':checked')) {
      $parent.find('input[type="checkbox"]').prop('checked', true);
    } else {
      $parent.find('input[type="checkbox"]').prop('checked', false);
    }
  }

  return self;
}();