bazookas.tooltips = (function() {
  var self = {};

  self.init = function(context) {
    bazookas.main
      .findInContext('.add-tooltip', context)
      .initElements(initTooltip);
    bazookas.main
      .findInContext('.add-popover', context)
      .initElements(initPopover);
  };

  function initTooltip() {
    $(this).tooltip();
  }

  function initPopover() {
    $(this).popover();
  }

  return self;
})();
