bazookas.youtubeUploader = (function () {
  var self = {};
  var STATUS_POLLING_INTERVAL_MILLIS = 10 * 1000; // ten seconds.
  var statusPollTimeout;

  self.init = function (context) {
    bazookas.main
      .findInContext('.js-youtube-modal', context)
      .initElements(initYoutubeModal);
  };

  window.youtubeUploaderSigninCallback =
  self.signinCallback = function (result) {
    // show upload form

    // TODO http://stackoverflow.com/questions/38751262/gapi-auth2-can-not-get-access-token
    // if (result.isSignedIn()) {
    //   self.user = result;
    //   showUploadForm();
    // }
    if (result.status.signed_in && result.access_token) {
      showUploadForm();
    }
  }

  function showUploadForm() {
    var $modal = $('.js-youtube-modal');

    gapi.client.request({
      path: '/youtube/v3/channels',
      params: {
        part: 'snippet',
        mine: true
      },
      callback: function(response) {
        if (response.error) {
          console.log(response.error.message);
          alert('Unable to retrieve channel');
        } else {
          $modal.find('.js-channel-name').text(response.items[0].snippet.title);
          $modal.find('.js-channel-thumbnail').attr('src', response.items[0].snippet.thumbnails.default.url);

          hide($modal.find('.js-sign-in'));
          show($modal.find('.js-upload-form, .js-upload-button, .js-upload-disclaimer'));
        }
      }
    });
  }

  // init user click handlers
  function initYoutubeModal() {
    var $modal = $(this);
    $modal
      .on('show.bs.modal', modalShowHandler)
      .on('hide.bs.modal', modalHideHandler);
    $modal
      .find('.js-upload-button')
      .on('click', uploadClickHandler);
    $modal
      .find('.js-confirm-button')
      .on('click', confirmClickHandler);
  }

  function modalShowHandler(event) {
    var $button = $(event.relatedTarget);
    $(this).find('.js-confirm-button').data('target-field', $button.data('target-field'));
  }
  function modalHideHandler(event) {
    // TODO cancel uploads!!

    clearTimeout(statusPollTimeout);
    resetView($(this));
  }
  function resetView($modal) {
    $modal.find('.js-upload-button').prop('disabled', false);
    show($modal.find('.js-upload-form, .js-upload-button'));
    hide($modal.find('.js-confirm-button, .js-upload-complete, .js-upload-progress'));
    $modal.find('.js-post-upload-status').html('');
  }


  function uploadClickHandler() {
    var $button = $(this).attr('disabled', true),
        $modal = $button.closest('.js-youtube-modal'),
        $form = $modal.find('.js-upload-form'),
        $progressContainer = $modal.find('.js-upload-progress'),
        metadata = {};

    show($progressContainer);
    hide($modal.find('.js-upload-form'));

    metadata.snippet = {
      title: $form.find('.js-title-input').val(),
      description: $form.find('.js-description-input').val(),
      tags: [], // can be array of strings
      categoryId: 17 // video category 17 is sports
    };
    metadata.status = {
      privacyStatus: "public",
      embeddable: true,
      license: 'youtube'
    };
    uploadFile($form.find('.js-file-input').get(0).files[0], metadata, uploadCompleteHandler.bind($modal), $progressContainer);
  }

  function uploadFile(file, metadata, completeHandler, $progressContainer) {
    var $progress = $progressContainer.find('.progress'),
        $percent = $progressContainer.find('.js-percent-transferred'),
        $bytes = $progressContainer.find('.js-bytes-transferred'),
        $bytesTotal = $progressContainer.find('.js-bytes-total');

    var uploader = new MediaUploader({
      baseUrl: 'https://www.googleapis.com/upload/youtube/v3/videos',
      file: file,
      token: gapi.auth.getToken().access_token, // TODO http://stackoverflow.com/questions/38751262/gapi-auth2-can-not-get-access-token
      metadata: metadata,
      params: {
        part: 'snippet,status,contentDetails',
      },
      onError: uploadErrorHandler,
      onProgress: uploadProgressHandler.bind($progressContainer, $progress, $percent, $bytes, $bytesTotal),
      onComplete: completeHandler
    });
    // This won't correspond to the *exact* start of the upload, but it should be close enough.
    // this.uploadStartTime = Date.now();
    uploader.upload();
  }

  function uploadErrorHandler(data) {
    var message = data;
    // Assuming the error is raised by the YouTube API, data will be
    // a JSON string with error.message set. That may not be the
    // only time onError will be raised, though.
    try {
      var errorResponse = JSON.parse(data);
      message = errorResponse.error.message;
    } finally {
      alert(message);
    }
  }

  function uploadProgressHandler($progress, $percent, $bytes, $bytesTotal, data) {
    // var currentTime = Date.now();
    var bytesUploaded = data.loaded;
    var bytesTotal = data.total;
    // The times are in millis, so we need to divide by 1000 to get seconds.
    var percentageComplete = Math.round((bytesUploaded * 100) / bytesTotal);
    // var bytesPerSecond = bytesUploaded / ((currentTime - this.uploadStartTime) / 1000);
    // var estimatedSecondsRemaining = (bytesTotal - bytesUploaded) / bytesPerSecond;

    $progress.children('.progress-bar').width(percentageComplete + '%');

    $percent.text(percentageComplete);
    $bytes.text(bytesUploaded);
    $bytesTotal.text(bytesTotal);
  }

  function uploadCompleteHandler(data) {
    var $modal = $(this),
        $complete = $modal.find('.js-upload-complete'),
        $confirm = $modal.find('.js-confirm-button'),
        $statusList = $modal.find('.js-post-upload-status'),
        uploadResponse = JSON.parse(data);

    hide($modal.find('.js-upload-progress, .js-upload-button'));
    show($complete);
    show($confirm);

    var videoUrl = 'https://www.youtube.com/watch?v=' + uploadResponse.id
    $confirm.data('video-url', videoUrl);
    $complete.find('.js-video-id').html('<a class="text-primary" href="' + videoUrl + '">' + videoUrl + '</a>');
    pollForVideoStatus.call($statusList, uploadResponse.id);
  }

  function pollForVideoStatus(videoId) {
    var $statusList = this;
    // add a spinner to the list to make users believe something is happening
    var $spinner = $statusList.find('li.js-spinner');
    if ($spinner.length === 0) {
      $spinner = $('<li class="js-spinner"><i class="fa fa-refresh fa-spin"></i></li>');
    }
    $statusList.append($spinner);

    gapi.client.request({
      path: '/youtube/v3/videos',
      params: {
        // part: 'status,player',
        part: 'status',
        id: videoId
      },
      callback: function(response) {
        // TODO translate status codes
        if (response.error) {
          // The status polling failed.
          console.log('status call failed', response);
          $statusList.append('<li>' + response.error.message + '</li>');
          statusPollTimeout = setTimeout(pollForVideoStatus.bind($statusList, videoId), STATUS_POLLING_INTERVAL_MILLIS);
        } else {
          var status = response.items[0].status;
          var uploadStatus = status.uploadStatus;

          // remove in case the status is final
          $spinner.remove();

          switch (uploadStatus) {
            // The video was successfully transcoded and is available.
            case 'processed':
              // $('#player').append(response.items[0].player.embedHtml);
              $statusList.append('<li class="text-success">Done! Video processed and ready!</li>');
              break;
            // This is a non-final status, so we need to poll again.
            case 'uploaded':
              $statusList.append('<li class="text-info">Upload status: ' + uploadStatus + '</li>');
              $statusList.append($spinner);
              statusPollTimeout = setTimeout(pollForVideoStatus.bind($statusList, videoId), STATUS_POLLING_INTERVAL_MILLIS);
              break;
            // BELOW ARE ALL FAILURES
            case 'deleted':
              $statusList.append('<li class="text-danger">This video was deleted</li>');
              break;
            case 'failed':
              $statusList.append('<li class="text-danger">Failed: ' + status.failureReason + '</li>');
              break;
            case 'rejected':
              $statusList.append('<li class="text-danger">Video rejected: ' + status.rejectionReason + '</li>');
              break;
            // All other statuses indicate a permanent transcoding failure.
            default:
              $statusList.append('<li class="text-danger">Video upload failed: ' + uploadStatus + '</li>');
              break;
          }
        }
      }
    });
  }

  function confirmClickHandler() {
    var $button = $(this);
    $($button.data('target-field')).val($button.data('video-url'));
  }

  function show($element) {
    $element.removeClass('hidden');
  }
  function hide($element) {
    $element.addClass('hidden');
  }

  return self;
})();
