bazookas.lessonPlan = (function () {
  var self = {},
    freeChecked = false,
    ignoreChange = true
  ;

  self.init = function (context) {
    bazookas.main
      .findInContext('#lesson_plan_admin_free', context)
      .initElements(setCheckedListener);

    bazookas.main
      .findInContext('.js-free-check', context)
      .initElements(setVideoFreeChecked);

    ignoreChange = false;
  };

  function setCheckedListener() {
    var $target = $(this);
    $target.on('change', freeCheckedHandler);
    freeChecked = $target.is(':checked');
  }

  function setVideoFreeChecked() {
    var $target = $(this);
    $target.on('change', changeSingleCheck);
    if (!ignoreChange) {
      $target.prop('checked', freeChecked);
    }
  }

  function changeSingleCheck(event) {
    var $target = $(event.target),
        checked = $target.is(':checked');
    if (!checked) {
      freeChecked = false;
      ignoreChange = true;
      $('#lesson_plan_admin_free').prop('checked', false);
      ignoreChange = false;
    }
  }

  function freeCheckedHandler(event) {
    var $target = $(event.target);
    freeChecked = $target.is(':checked');
    if (!ignoreChange) {
      $('.js-free-check').prop('checked', freeChecked);
    }
  }

  return self;
})();
